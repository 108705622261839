<template>
    <div>
        <b-row>
            <b-col cols="4" class="p-0 m-0">
                <data-label :data="selectedPpeRequest.status" 
                    :labelName="'Status:'" :labelRight="false"  />
            </b-col>
            <b-col cols="4" class="p-0 m-0">
                <data-label :data="selectedPpeRequest.requestDate | dateTimeFilter" 
                    :labelName="'Requested Date:'" :labelRight="false"  />
            </b-col>
            <b-col cols="4"></b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="p-0 m-0">
                <data-label :data="selectedPpeRequest.approveDate | dateTimeFilter" 
                    :labelName="'Acknowledged Date:'" :labelRight="false"  />
            </b-col>
            <b-col cols="4" class="p-0 m-0">
                <data-label :data="selectedPpeRequest.approvedBy" 
                    :labelName="'Acknowledged By:'" :labelRight="false"  />
            </b-col>
            <b-col cols="4" class="p-0 m-0">
                <data-label :data="selectedPpeRequest.dateReceived | dateTimeFilter" 
                    :labelName="'Signed Date:'" :labelRight="false"  />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <label class="headLabel" style="font-weight: 600; colour: #212529;">Rejection Reason</label>
            </b-col>
            <b-col cols="12" >
                <p >{{selectedPpeRequest.rejectionReason === null ? 'N/A' : selectedPpeRequest.rejectionReason}}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
    
                <b-table striped hover :items="tablePerishableData.dataSource" :fields="tablePerishableData.tableColumns" 
                    :busy="tablePerishableData.isLoading" small sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>
                    <template #cell(expiryDate)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.expiryDate | dateTimeFilter}}</span>
                        </b-row>
                    </template>
                    <template #cell(approved)="row">
                        <b-row align-v="center">
                            <b-form-checkbox
                                v-model="row.item.approved"
                                :value="true"
                                :disabled="true"
                                :unchecked-value="false">
                                Accepted
                            </b-form-checkbox>
                        </b-row>
                    </template>

                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                :busy="tableData.isLoading" small sort-icon-left>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </div>
                    </template>

                    <template #cell(dateRecieved)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.dateRecieved | dateTimeFilter}}</span>
                        </b-row>
                    </template>
                    <template #cell(size)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.size !== '' && row.item.size !== null ? row.item.size : 'N/A'}}</span>
                        </b-row>
                    </template>
                    <template #cell(quantity)="row">
                        <b-row align-v="center">
                            <span class="mr-auto">{{row.item.quantity !== '' && row.item.quantity !== null ? row.item.quantity : '0'}}</span>
                        </b-row>
                    </template>
                    <template #cell(approved)="row">
                        <b-row align-v="center">
                            <b-form-checkbox
                                v-model="row.item.approved"
                                :value="true"
                                :disabled="true"
                                :unchecked-value="false">
                                Accepted
                            </b-form-checkbox>
                        </b-row>
                    </template>
                    
                </b-table>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>
export default {
    props: {
        data: Object
    },
    data: () => ({
        tablePerishableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
            {
                label: 'Description',
                key: 'description',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Serial Number',
                key: 'serialNumber',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
            ]
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
            {
                label: 'Description',
                key: 'description',
                sortable: true,
                tdClass: ''
            },
            {
                label: 'Quantity',
                key: 'quantity',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Received Date',
                key: 'dateRecieved',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Size',
                key: 'size',
                sortable: false,
                tdClass: ''
            },
            {
                label: 'Approve',
                key: 'approved',
                sortable: false,
                tdClass: ''
            }
        ]
        }
    }),
    created() {
        this.selectedPpeRequest = this.data.result
        let replenishable = []
        let nonreplenishable = []
        this.data.result.ppEitems.forEach(item => {
            if (item.perishable) {
                nonreplenishable.push(item)
            }
            else {
                replenishable.push(item)
            }
        });
        this.tableData.dataSource = replenishable
        this.tablePerishableData.dataSource = nonreplenishable
        this.tableData.isLoading = false
        this.tablePerishableData.isLoading = false
    },
    methods: {
    },
    mutations: {
    }
}
</script>