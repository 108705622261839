<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="9">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>View PPE Request</h4>
                        </b-col>
                    </b-row>
                    <ppeDisplayList :isDisplayingForSuper="true" v-if="state !== 'initializing'"/>
                    
                    <b-row v-if="state === 'initiliazing'">
                        <b-col class="text-center">
                            <b-spinner large></b-spinner>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="3" v-if="state !== 'initializing'">
                <b-card>
                    <b-row>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.status" 
                            :labelName="'Status:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.requestDate | dateTimeFilter" 
                            :labelName="'Requested Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.createdBy" 
                            :labelName="'Requested By:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">
                        <data-label :data="selectedPpeRequest.createdFor" 
                            :labelName="'Requested For:'" :labelRight="false"  /></b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status !== 'New'">
                        <data-label :data="selectedPpeRequest.approveDate | dateTimeFilter" 
                            :labelName="'Acknowledged Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status !== 'New'">
                        <data-label :data="selectedPpeRequest.approvedBy" 
                            :labelName="'Acknowledged By:'" :labelRight="false"  /></b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status === 'Recieved'">
                        <data-label :data="selectedPpeRequest.dateReceived | dateTimeFilter" 
                            :labelName="'Signed Date:'" :labelRight="false"  /></b-col>
                        <b-col class="p-0" cols="12">

                        <data-label :data="selectedPpeRequest.rejectionReason"  v-if="selectedPpeRequest.status !== 'New'"
                            :labelName="'Rejection Reason:'" :labelRight="false"  /></b-col>


                        <b-col cols="12" v-if="selectedPpeRequest.status === 'New' && rejectedItems > 0">
                            <label class="bold">Rejection Reason</label>
                        </b-col>
                        <b-col cols="12" v-if="selectedPpeRequest.status === 'New' && rejectedItems > 0">
                            <b-form-textarea v-model="selectedPpeRequest.rejectionReason"></b-form-textarea>
                        </b-col>
                        
                        <b-col cols="12" v-if="selectedPpeRequest.status === 'New'" class="mt-2">
                            <b-button variant="primary" block size="sm" @click="doApprovePpeRequest()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner> Acknowledge</b-button>
                        </b-col>
                        <b-col cols="12" v-if="selectedPpeRequest.status === 'Acquiring Items'" class="mt-2">
                            <b-button variant="primary" block size="sm" @click="doReadyForPickup()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner> Ready For Pickup</b-button>
                        </b-col>

                        <b-col class="p-0" cols="12" v-if="selectedPpeRequest.status === 'Received'">
                        <data-label :data="selectedPpeRequest.dateReceived | dateTimeFilter" 
                            :labelName="'Date Received:'" :labelRight="false"  /></b-col>
                        
                        <b-col cols="12" class="mt-2">
                            <label class="bold">Previous Requests</label>
                        </b-col>
                        <b-col cols="12">
                            <b-button v-for="(item, index) in top10Items" :key="index" class="p-1 " variant="info"
                                    @click="showRequest(item.ppErequestId)" block>
                                    <b-row class="m-0 p-0" align-h="between">
                                        <span>{{item.requestDate | dateTimeFilter}}</span>
                                        <span>{{item.status}}</span>
                                    </b-row>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                
            </b-col>
        </b-row>

        
        <b-modal id="show-request-modal" hide-footer size="xl" no-header @close="closeRequestModal()">
            <b-row v-if="modalState === 'show'">
                <b-col>
                    <ppeDisplayOldRequest :data="viewRequest"/>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import ppeDisplayList from '@/components/ppeDisplayList.vue'
import ppeDisplayOldRequest from '@/components/ppeDisplayOldRequest.vue'
import { bus } from '../../main'
import { mapActions, mapState, mapMutations } from 'vuex'


export default {
  components:{
    ppeDisplayList,
    ppeDisplayOldRequest
  },
  data: () => ({
      state: 'initializing',
      modalState: 'loading',
      rejectedItems: 0,
      top10Items: [],
      viewRequest: {
          ppEitems: []
      }
  }),
  created(){
    if (this.selectedPpeRequest === null) {
        this.back()
    }
    
    this.setBreadcrumb([
        {
            text: 'Current PPE Request'
        },
        {
            text: 'View',
            active: true
        }
    ])
      
    bus.$off()
    
    bus.$on('ppeRequestCountDeniedItems', (newChange) => {
        if (!newChange) {
            this.rejectedItems++
        } else {
            this.rejectedItems--
        }
    })

    this.getTop10()
    .then((res) => {
        this.state = 'show'
        this.top10Items = res.data
    })
  },
  methods: {
    ...mapActions(['approvePpeRequest', 'getTop10', 'getPpeRequestForDisplay', 'setRequestToReadyForPickup']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    doReadyForPickup(){
        this.state = 'loading'
        this.setRequestToReadyForPickup()
        .then(() => {
            this.back()
        })
        .catch(() => {
            this.state = 'show'
        })
    },
    showRequest(requestItem){
        this.modalState = 'loading'
        this.$root.$emit('bv::show::modal', 'show-request-modal', '#btnShow')
        
        this.$store.commit('setPpeRequestIdRequest', requestItem)
        this.getPpeRequestForDisplay()
        .then((res) => {
            this.modalState = 'show'
            this.viewRequest = res.data
        })
    },
    closeRequestModal(){
        this.$root.$emit('bv::hide::modal', 'show-request-modal', '#btnShow')
    },
    back(){
        this.$router.back()
    },
    doApprovePpeRequest(){
        this.state = 'loading'
        let newList = this.selectedPpeRequest.replenishable.concat(this.selectedPpeRequest.nonreplenishable)

        this.selectedPpeRequest.ppEitems = newList
        this.approvePpeRequest()
        .then(() => {
            this.state = 'show'
            this.back()
        })
        .catch(() => {
            this.state = 'show'
        })
    }
  },
  computed: {
      ...mapState(['selectedPpeRequest'])
  }
    
}
</script>